<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-sm" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Purpose Type</th>
              <th>Created At</th>
              <th>Status</th>
              <th>Fuel Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requisition, index) in requisitions.data" :key="index">
              <td>{{ requisitions.from + index}}</td>
              <td>{{ requisition.fuel_purpose_type.fuel_purpose_type_desc }}</td>
              <td>{{ customDate(requisition.created_at) }}</td>
              <td>
                <a-tag color="#f50" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && !requisition.fuel_requisition_status.includes('ADMIN_APPROVED') && !requisition.fuel_requisition_status.includes('ADMIN_REJECTED') ">Pending</a-tag>
                <a-tag color="#f50" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && requisition.fuel_requisition_status.includes('ADMIN_REJECTED')">Rejected</a-tag>
                <a-tag color="#87d068" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && requisition.fuel_requisition_status.includes('ADMIN_APPROVED')">Approved</a-tag>
                <a href="javascript: void(0);" v-b-modal.fuel-requisition-status-modal @click="$bvModal.show('fuel-requisition-status-modal'), status(requisition.max_fuel_requisition_status, requisition)" class="btn btn-sm btn-info">
                  <i class="fa fa-level-up"/>
                </a>
              </td>
              <td>
                <a-tag color="#f50" v-if="requisition.fuel_collection_status == 'Collect'">Collect</a-tag>
                <a-tag color="#87d068" v-else>Collected</a-tag>
              </td>
              <td>
                <router-link :to="{ name: 'hubFuelRequisitionView', params: { fuel_requisition_id: requisition.id } }" class="btn btn-sm btn-info mr-1" tag="a">
                  <i class="fe fe-eye"/> View
                </router-link>
                <a class="btn btn-sm btn-success mr-1" href="javascript: void(0);" @click.prevent="consumptionCreate(requisition)"><i class="fa fa-plus mr-1"></i>Create</a>
                <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="consumptionHistory(requisition.hub_fuel_consumption_total, requisition.hub_fuel_consumptions)"><i class="fa fa-info mr-1"></i>Details</a>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="requisitions.total === 0 || flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="requisitions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <consumption-history
      :hub_fuel_consumptions="hub_fuel_consumptions"
      :hub_fuel_consumption_total="hub_fuel_consumption_total"
      @search="search"
    ></consumption-history>
    <consumption-create
      :requisition="requisition"
      :remaining_fuel="remaining_fuel"
      @search="search"
    ></consumption-create>
    <status :current="current" :requisition="requisition"></status>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import consumptionHistory from '@/views/fuelConsumption/modal/consumption_history'
import consumptionCreate from '@/views/fuelConsumption/modal/consumption_create'
import Status from '@/views/fuelConsumption/modal/status'

export default {
  name: 'List',
  components: { Empty, consumptionHistory, consumptionCreate, Status },
  data() {
    return {
      hub_fuel_consumptions: {},
      remaining_fuel: '',
      hub_fuel_consumption_total: '',
      requisitions: {},
      requisition: {},
      loading: false,
      loader: false,
      flag: true,
      btnLoading: false,
      btnCollected: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      current: 0,
      search_data: {
        date_range: '',
      },
    }
  },
  mounted() { },
  methods: {
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    search() {
      this.loading = true
      this.btnLoading = true
      this.flag = false
      apiClient.post('api/hub/consumption/fuel-requisition/search', this.search_data)
        .then(response => {
          this.loading = false
          this.btnLoading = false
          this.flag = false
          this.requisitions = response.data.requisitions
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      apiClient.post('api/hub/consumption/fuel-requisition/search?page=' + page, this.search_data)
        .then(response => {
          this.requisitions = response.data.requisitions
        })
    },
    consumptionHistory(hubFuelConsumptionTotal, hubFuelConsumptions) {
      this.hub_fuel_consumption_total = ''
      this.hub_fuel_consumptions = {}
      this.hub_fuel_consumption_total = hubFuelConsumptionTotal
      this.hub_fuel_consumptions = hubFuelConsumptions
      this.$bvModal.show('fuel-consumption-history-modal')
    },
    consumptionCreate(requisition) {
      this.requisition = ''
      this.remaining_fuel = ''
      const remainingFuel = parseFloat(requisition.requisite_liter) - parseFloat(requisition.hub_fuel_consumption_total)
      this.remaining_fuel = remainingFuel
      this.requisition = requisition
      this.$bvModal.show('fuel-consumption-create-modal')
    },
    status(fuelRequisitionStatus, requisition) {
      this.current = 0
      this.requisition = ''
      this.current = fuelRequisitionStatus
      this.requisition = requisition
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
