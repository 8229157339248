<template>
  <div>
    <div v-if="Object.keys(requisition).length !== 0">
      <b-modal id="fuel-consumption-create-modal" title="Consumption Create" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">
          <form action="" enctype="multipart/form-data" files="true" role="form">
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="requisite_liter" class="col-form-label col-form-label-sm">Requisite Liter</label>
                <input type="text" disabled readonly v-model="requisition.requisite_liter" id="requisite_liter" name="requisite_liter" class="form-control form-control-sm"/>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="hub_fuel_consumption_total" class="col-form-label col-form-label-sm">Fuel Total Consumption</label>
                <input type="text" disabled readonly v-model="requisition.hub_fuel_consumption_total" id="hub_fuel_consumption_total" name="hub_fuel_consumption_total" class="form-control form-control-sm"/>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="requisite_liter" class="col-form-label col-form-label-sm"><span style="color: red">Remaining Fuel</span></label>
                <input type="text" disabled readonly v-model="remaining_fuel" id="remaining_fuel" name="remaining_fuel" class="form-control form-control-sm is-invalid"/>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="fuel_liter" class="col-form-label col-form-label-sm">Fuel Stock Consumption <span class="custom-required">*</span></label>
                <input type="text" :disabled="remaining_fuel == 0 ? true : false" v-model="requisition.fuel_liter" v-validate="{ required: true, max: 7, regex: /^[0-9]*(?:\.[0-9]{0,2})?$/ }" @change="fuelConsumptionAmountCheck($event.target.value)"  id="fuel_liter" name="fuel_liter" class="form-control form-control-sm" placeholder="Enter fuel stock consumption"/>
                <div class="invalid-feedback">Fuel stock consumption is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="generator_reading" class="col-form-label col-form-label-sm">Generator Reading <span class="custom-required">*</span></label>
                <input type="file" :disabled="remaining_fuel == 0 ? true : false" ref="generatorReading" v-validate="'required'" id="generator_reading" name="generator_reading" @change="getGeneratorReading()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
                <div class="invalid-feedback">Generator reading is required</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="fuel_refilling" class="col-form-label col-form-label-sm">Fuel Refilling <span class="custom-required">*</span></label>
                <input type="file" :disabled="remaining_fuel == 0 ? true : false" ref="fuelRefilling" v-validate="'required'" id="fuel_refilling" name="fuel_refilling" @change="getFuelRefilling()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
                <div class="invalid-feedback">Fuel refilling is required</div>
              </div>
            </div>
            <div class="form-row mt-3 mb-3">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="update">Consumption Stock</a-button>
                <a-button type="danger" class="text-center" @click="$bvModal.hide('fuel-consumption-create-modal')">Cancel</a-button>
              </div>
            </div>
          </form>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'consumption_create',
  props: ['requisition', 'remaining_fuel'],
  data() {
    return {
      validation_errors: {},
      loader: false,
      show: false,
      generator_reading: '',
      fuel_refilling: '',
    }
  },
  mounted() {},
  methods: {
    fuelConsumptionAmountCheck($event) {
      const totalConsumptionAmount = $event
      const remainingStockAmount = parseFloat(this.remaining_fuel || 0)
      if (parseFloat(totalConsumptionAmount) > remainingStockAmount) {
        this.$notification.error({
          message: 'Consumption fuel amount is crossing total fuel amount',
        })
        this.requisition.fuel_liter = ''
      }
    },
    getGeneratorReading() {
      this.generator_reading = this.$refs.generatorReading.files[0]
      if (this.generator_reading.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.generator_reading = ''
      }
    },
    getFuelRefilling() {
      this.fuel_refilling = this.$refs.fuelRefilling.files[0]
      if (this.fuel_refilling.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.fuel_refilling = ''
      }
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('fuel_liter', this.requisition.fuel_liter)
          formData.append('id', this.requisition.id)
          formData.append('generator_reading', this.generator_reading)
          formData.append('fuel_refilling', this.fuel_refilling)
          apiClient.post('api/hub/consumption/fuel-requisition/store', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              this.loader = false
              this.$bvModal.hide('fuel-consumption-create-modal')
              if (!response.data.error) {
                this.$emit('search')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
